import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  BackendService,
  BaseApiService,
  RequestFacadeModel,
  RequestModel,
  RequestType,
  ROUTES,
} from '@cf/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AccountService, IAccount } from '../account';
import { EmailValidationDto, GoogleAuthDto, LoginCredentialsDto, RegisterFormDto } from './dtos';
import { IAuthSuggestionResponse, ITokens } from './interfaces';

import { injectEnvironment } from '@cf/core';

import { Actions } from '@ngneat/effects-ng';
import { CalendarStoreService } from '../calendars';
import { OrganizationsStore } from '../organizations/state/organizations.store';
import { PaymentStoreService } from '../payment';
import { connectCalendar } from './effects';
@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseApiService {
  private readonly organizationStore = inject(OrganizationsStore);

  public redirectUrl!: string;

  public isBookingApp!: boolean;

  public broadcastChannel = new BroadcastChannel('AUTH_HANDLER');

  public isAuthorized$: Observable<boolean> = this.accountService.isAuthorized$;

  private environment = injectEnvironment();

  constructor(
    backendService: BackendService,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly accountService: AccountService,
    private readonly calendarStoreService: CalendarStoreService,
    private readonly paymentStoreService: PaymentStoreService,
    private readonly cookieService: CookieService,
    private readonly zone: NgZone,
    private actions: Actions,
  ) {
    super(backendService, ROUTES.auth);
    this.handleBC();
  }

  public checkEmail(emailValidationDto: EmailValidationDto): Observable<IAuthSuggestionResponse> {
    const request: RequestModel<EmailValidationDto> = new RequestModel<EmailValidationDto>({
      url: this.getFullUrl('email'),
      requestBody: emailValidationDto,
    });
    const requestFacade: RequestFacadeModel<EmailValidationDto> =
      new RequestFacadeModel<EmailValidationDto>({
        requestType: RequestType.post,
        request,
      });
    return this.send<IAuthSuggestionResponse, EmailValidationDto>(requestFacade);
  }

  public login(loginDto: LoginCredentialsDto): Observable<ITokens> {
    const request: RequestModel<LoginCredentialsDto> = new RequestModel<LoginCredentialsDto>({
      url: this.getFullUrl('token'),
      requestBody: loginDto,
    });
    const requestFacade: RequestFacadeModel<LoginCredentialsDto> =
      new RequestFacadeModel<LoginCredentialsDto>({
        requestType: RequestType.post,
        request,
      });
    return this.send<ITokens, LoginCredentialsDto>(requestFacade).pipe(
      tap(() => {
        this.handleSuccess();
        // eslint-disable-next-line no-untranslated-string/no-untranslated-string
        this.addDatalayer('sign_up', 'Email');
      }),
      catchError((error: HttpErrorResponse) => {
        throw error;
      }),
    );
  }

  public register(registerFormDto: RegisterFormDto): Observable<IAccount> {
    return this.accountService.register(registerFormDto);
  }

  public logout(isBookinkPage = false): void {
    const request: RequestModel<void> = new RequestModel<void>({
      url: this.getFullUrl('logout'),
    });

    const requestFacade: RequestFacadeModel<void> = new RequestFacadeModel<void>({
      requestType: RequestType.post,
      request,
    });

    this.send<unknown, void>(requestFacade).subscribe({
      next: () => {
        this.broadcastChannel.postMessage(null);
        this.killSession(true, isBookinkPage);
      },
      error: () => {
        this.broadcastChannel.postMessage(null);
        this.killSession(false, isBookinkPage);
      },
    });
  }

  public killSession(preventNavigate = false, isBookinkPage = false): void {
    localStorage.removeItem('hideContactAlert');
    this.cookieService.delete('auth_token');
    this.cookieService.delete('flow_key', '/');
    this.accountService.logout();
    this.calendarStoreService.reset();
    this.paymentStoreService.reset();
    this.organizationStore.reset();
    if (isBookinkPage) {
      return;
    }
    if (!preventNavigate) {
      window.location.href = this.environment.publicUrl;
    } else {
      void this.router.navigate(['/auth/login']);
    }
  }

  isAuthorized = computed(() => !!this.accountService.$account()?.id);

  addDatalayer(
    event: 'sign_up' | 'login' = 'sign_up',
    signup_method: 'Google' | 'Microsoft' | 'Email' = 'Email',
  ) {
    (window as any).dataLayer?.push({
      event,
      signup_method,
    });
  }

  public handleSuccess(): void {
    this.broadcastChannel.postMessage({});
    this.accountService.getAccountProfile$().subscribe((profile) => {
      if (this.isBookingApp) {
        window.close();
        return;
      }

      this.zone.run(() => {
        this.modalService.dismissAll();
        this.router.navigate([`/dashboard`]);
      });

      if (profile.show_connect_calendar) {
        this.actions.dispatch(connectCalendar({ newUser: true }));
      }
    });
    this.calendarStoreService.getCalendars();
    this.paymentStoreService.getPaymentList();
  }

  public microsoft(redirectUriKey?: string) {
    const request: RequestModel = redirectUriKey
      ? new RequestModel({
          url: this.getFullUrl('microsoft'),
        }).withQuery({ redirect_uri_key: redirectUriKey })
      : new RequestModel({
          url: this.getFullUrl('microsoft'),
        });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });
    return this.send(requestFacade);
  }

  public microsoftLogin(dto: any) {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl('microsoft'),
      requestBody: dto,
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.post,
      request,
    });
    return this.send(requestFacade);
  }

  public loginByGoogle(googleAuthDto: GoogleAuthDto): Observable<ITokens> {
    const request: RequestModel<GoogleAuthDto> = new RequestModel<GoogleAuthDto>({
      url: this.getFullUrl('google'),
      requestBody: googleAuthDto,
    });
    const requestFacade: RequestFacadeModel<GoogleAuthDto> = new RequestFacadeModel<GoogleAuthDto>({
      requestType: RequestType.post,
      request,
    });
    return this.send<ITokens, GoogleAuthDto>(requestFacade).pipe(
      tap(() => {
        this.handleSuccess();
        // eslint-disable-next-line no-untranslated-string/no-untranslated-string
        this.addDatalayer('sign_up', 'Google');
      }),
      catchError((error: HttpErrorResponse) => {
        throw error;
      }),
    );
  }

  public loginByGoogleToken(
    googleAuthDto: {
      timezone: string | null;
      token: string;
    },
    endpoint = 'google-token',
  ): Observable<ITokens> {
    const request: RequestModel<{ timezone: string | null; token: string }> = new RequestModel({
      url: this.getFullUrl(endpoint),
      requestBody: googleAuthDto,
    });
    const requestFacade: RequestFacadeModel<{
      timezone: string | null;
      token: string;
    }> = new RequestFacadeModel({
      requestType: RequestType.post,
      request,
    });
    return this.send<ITokens, any>(requestFacade).pipe(
      tap(() => {
        this.handleSuccess();
        // eslint-disable-next-line no-untranslated-string/no-untranslated-string
        this.addDatalayer('sign_up', 'Google');
      }),
    );
  }

  private handleBC(): void {
    this.broadcastChannel.onmessage = (event: MessageEvent<ITokens>) => {
      if (event.data) {
        this.modalService.dismissAll();
        this.accountService.getAccountProfile();
      } else {
        this.killSession(true);
      }
    };
  }
}
