const ROUTES = {
  default: '',
  dashboard: 'dashboard',
  events: 'events',
  connectedCalendar: 'connected-calendar',
  availability: 'availability',
  auth: 'auth',
  profile: 'profile',
};

export { ROUTES };
